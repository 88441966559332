@import '../../../../scss/theme-bootstrap';

.faq-formatter {
  @include container-max-width-x-large;
  @include container-padding-rules;
  &__title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 0 60px;
    border-bottom: 1px solid $cr19-border-light;
    @media #{$cr19-large-up} {
      flex-wrap: nowrap;
      padding: 24px 0 72px;
    }
  }
  &__header {
    flex: 0 0 100%;
    padding-#{$rdirection}: 0;
    @media #{$cr19-large-up} {
      flex: 0 0 50%;
      padding-#{$rdirection}: 23px;
    }
    .style--bare {
      font-family: $base-bolder-font-family;
      font-size: 20px;
      letter-spacing: -0.01em;
      line-height: get-line-height(20px, 24px);
      @media #{$cr19-large-up} {
        font-size: 28px;
        letter-spacing: -0.02em;
        line-height: get-line-height(28px, 32px);
      }
    }
  }
  &__description {
    flex: 0 0 100%;
    padding-#{$ldirection}: 0;
    padding-#{$rdirection}: 0;
    @media #{$cr19-large-up} {
      flex: 0 0 50%;
      padding-#{$ldirection}: 12px;
      padding-#{$rdirection}: 23px;
    }
    .style--bare {
      font-family: $base-bolder-font-family;
      font-size: 15px;
      letter-spacing: -0.01em;
      line-height: get-line-height(15px, 20px);
      @media #{$cr19-large-up} {
        font-size: 17px;
        line-height: get-line-height(17px, 24px);
      }
    }
  }
  &__content {
    .faq-content {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0;
      border-bottom: 1px solid $cr19-border-light;
      @media #{$cr19-large-up} {
        flex-wrap: nowrap;
        padding: 24px 0;
      }
      &[aria-expanded='true'] {
        .faq-content__toggle {
          &:before {
            transform: rotate(90deg);
          }
        }
      }
      &__toggle {
        display: block;
        cursor: pointer;
        position: absolute;
        #{$rdirection}: 0;
        top: 23px;
        transform: none;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        &:before,
        &:after {
          content: '';
          position: absolute;
          background-color: $color-black;
          transition: transform 0.25s ease-out;
        }
        /* Vertical line */
        &:before {
          top: 0;
          #{$rdirection}: calc(50% - 1px);
          width: 2px;
          height: 100%;
        }
        /* horizontal line */
        &:after {
          top: calc(50% - 1px);
          #{$rdirection}: 0;
          width: 100%;
          height: 2px;
        }
        [aria-expanded='true'] & {
          &:before {
            transform: rotate(90deg);
          }
        }
      }
      &__question,
      &__answer,
      &__button {
        flex: 0 0 100%;
        @media #{$cr19-large-up} {
          flex: 0 0 50%;
        }
      }
      &__question {
        cursor: pointer;
        padding-#{$rdirection}: 17%;
        @media #{$cr19-large-up} {
          padding-#{$rdirection}: 28px;
        }
        .style--bare {
          font-family: $base-bolder-font-family;
          font-size: 15px;
          letter-spacing: -0.01em;
          line-height: get-line-height(15px, 20px);
          @media #{$cr19-large-up} {
            font-size: 17px;
            line-height: get-line-height(17px, 24px);
          }
        }
      }
      &__answer {
        height: auto;
        transition: height 0.2s ease-in;
        visibility: visible;
        &[aria-hidden='true'] {
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
        &[aria-hidden='false'] {
          padding-#{$rdirection}: 17%;
          padding-#{$ldirection}: 0;
          @media #{$cr19-large-up} {
            padding-#{$rdirection}: 8%;
            padding-#{$ldirection}: 12px;
          }
        }
        .style--bare {
          font-size: 15px;
          letter-spacing: 0.02em;
          line-height: get-line-height(15px, 20px);
          margin: 0;
          @media #{$cr19-medium-up} {
            font-size: 17px;
            line-height: get-line-height(17px, 24px);
          }
        }
      }
      &__button {
        color: inherit;
      }
    }
  }
}
